<template>
    <v-container class="my-2 text-left">
        <v-btn
            color="indigo"
            dark
            medium
            @click="show = !show"
        >
            Dodaj zadanie
        </v-btn>

        <v-dialog v-model="show" max-width="1000px">
            <v-card>
                <v-toolbar class="indigo" dark flat>
                    <v-toolbar-title>Dodaj zadanie</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            label="Tytuł"
                            name="name"
                            type="text"
                            v-model="name"
                            clearable
                            :error-messages="nameErrors"
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"   
                        />

                        <v-text-field
                            v-model="characters"
                            label="Liczba znaków"
                            required
                            :error-messages="characterErrors"
                            @input="$v.characters.$touch()"
                            @blur="$v.characters.$touch()"
                            clearable
                        ></v-text-field>

                        <VueEditor v-model="description" class='mb-4' />

                        <v-file-input
                            id="file"
                            clearable
                            v-on:change="onAddNewFile"
                            label="Dodaj załącznik"
                        ></v-file-input>

                        <p v-if="feedback">{{ feedback }}</p>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="ma-2"
                        :disabled="$v.$invalid"
                        color="secondary"
                        @click="add"
						:loading="isLoaded"
                    >
                        Dodaj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, decimal } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';
import { VueEditor } from 'vue2-editor';

const token = localStorage.getItem('user');

export default{
    mixins: [validationMixin, errorMsg],
    components: {
        VueEditor,
	},
    data(){
        return{
            file: null,
			isLoaded: false,
            loader: null,
            name: null,
            description: null,
            characters: 3000,
            show :false,
            feedback: ''
        }
    },
    validations: {
        name: {
            required,
        },
        description: {
            required,
        },
        characters: {
            decimal,
            required
        },
    },
    methods: {
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        add(){
            if(!this.$v.$invalid){
				this.isLoaded = true
                this.$axios.post(`${this.url}api/course-tasks`, {
                    title: this.name,
                    characters: this.characters,
                    desciption: this.description
                }, { headers: { 'x-access-token': token } }).then((resp)=>{
                    this.sendFile(resp.data.id);
                    this.resetValidation();
					this.isLoaded = false;
                    this.description = '';
                    this.characters = 3000;
                    this.show = false
                }).catch(()=>{
					this.isLoaded = false;
				})
            }
        },
        sendFile(taskId) {
            if (this.file != null) {
                let formData = new FormData();

                formData.append("file", this.file);
                formData.append("id", taskId);
                formData.append("emmit", false);
                this.$axios
                    .post(this.url + "api/uploads/course-attach", formData, { headers: { 'x-access-token': token } })
                    .then(() => {
                        this.file = null;
                    });
            }
        },
        onAddNewFile() {
            const fileToUpload = document.querySelector("#file").files[0];
            this.file = fileToUpload;
        },

    },
}
</script>
