<template>
    <div>
        <AddCourseTask />
        <CourseTaskTable />
    </div>
  </template>
  
  <script>
  import AddCourseTask from '@/components/molecules/AddCourseTask.vue';
  import CourseTaskTable from '@/components/templates/CourseTaskTable.vue';
  
  export default {
    components: {
      AddCourseTask,
      CourseTaskTable
    }
  }
  </script>
  
  <style>
  
  </style>